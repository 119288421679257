<template>
  <div class="relative flex flex-1 flex-col">
    <main class="container mx-auto flex flex-1 flex-col">
      <div class="flex flex-1 flex-col">
        <slot />
      </div>
    </main>
    <SiteFooter />
  </div>
</template>

<script setup lang="ts">
const route = useRoute()
</script>

  <style scoped>
  </style>
